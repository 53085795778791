import { SlideIdEnum, StorieIdEnum } from './stories.enum';
import { Story } from './stories.model';

export const STORIES_DATA: Story[] = [
  {
    id: StorieIdEnum.FREE_REPLACEMENTS,
    image: 'story_1.png',
    text: 'Бесплатные замены',
    isShowed: false,
    slides: [
      {
        id: SlideIdEnum.FREE_REPLACEMENT,
        title: 'Замена блюд',
        imageMiddle: '/assets/images/stories/1/free-replacement_middle_1.png',
        textParagraphs: ['Более 30 вариантов блюд для замены каждый день'],
        backgroundColor: '#F6F0DD',
      },
      {
        id: SlideIdEnum.FREE_REPLACEMENT,
        title: 'Простая инструкция по замене',
        imageBottom: '/assets/images/stories/1/free-replacement_middle_2.png',
        textParagraphs: ['Закажи и оплати подписку:'],
        listItems: ['Зайди в моё меню', 'Выбери блюдо, которое хочешь заменить'],
        backgroundColor: '#F6F0DD',
      },
      {
        id: SlideIdEnum.FREE_REPLACEMENT,
        title: 'Далее',
        imageBottom: '/assets/images/stories/1/free-replacement_middle_3.png',
        textParagraphs: [],
        listItems: ['Выбери блюдо для замены', 'Проверни операцию снова, если хочешь 2. 2. заменить несколько блюд в дне'],
        backgroundColor: '#F6F0DD',
      },
      {
        id: SlideIdEnum.FREE_REPLACEMENT,
        title: 'Финальный шаг наверх',
        imageBottom: '/assets/images/stories/1/free-replacement_middle_4.png',
        textParagraphs: ['Подтверди или оплати замену, чтобы она сохранилась'],
        backgroundColor: '#F6F0DD',
      },
      {
        id: SlideIdEnum.FREE_REPLACEMENT,
        title: 'Получай доставку <br> и наслаждайся своим <br> уникальным меню',
        imageBottom: '/assets/images/stories/1/free-replacement_middle_5.png',
        textParagraphs: [''],
        backgroundColor: '#F6F0DD',
      },
    ],
  },
  {
    id: StorieIdEnum.PERSONAL_DISCOUNTS,
    image: 'story_2.png',
    text: 'Персональные скидки',
    isShowed: false,
    slides: [
      {
        id: SlideIdEnum.PERSONAL_DISCOUNT_1,
        imageFull: '/assets/images/stories/2/discount_full_1.png',
        buttonTitleAbove: 'С близкими вместе',
        buttonTextAbove:
          'Оформляйте несколько подписок, совпадающих по адресу и графику доставки. И получайте дополнительную скидку до 17%',
        backgroundColor: '#EEBD92',
        buttonText: 'Оформить подписку',
        buttonLink: '/cabinet/create/list',
      },
      {
        id: SlideIdEnum.PERSONAL_DISCOUNT_2,
        imageFull: '/assets/images/stories/2/discount_full_2.png',
        backgroundColor: '#FFC979',
        buttonTitleAbove: 'Не оставим вас голодными',
        buttonTextAbove:
          'Подключите автопродление и тогда коробочки с едой будут у вас без перерыва. А еще даем скидку 5% за подключение опции',
        buttonText: 'Продлить подписку',
        buttonLink: '/cabinet/subscriptions',
      },
      {
        id: SlideIdEnum.PERSONAL_DISCOUNT_2,
        imageFull: '/assets/images/stories/2/discount_full_3.png',
        backgroundColor: '#FFC979',
        buttonTitleAbove: 'Поделитесь мнением',
        buttonTextAbove:
          'Расскажите было ли вам вкусно с justfood  и получите скидку 5%. Отставить отзыв можно в Яндексе, Google, VK с отметкой justfood или в наших соцсетях.',
        buttonText: 'Оставить отзыв',
        buttonLink: 'https://vk.com/justfoodpro',
      },
    ],
  },
  {
    id: StorieIdEnum.INVITE_FRIENDS,
    image: 'story_3.png',
    text: 'Пригласи друга',
    isShowed: false,
    slides: [
      {
        id: SlideIdEnum.INVITE_FRIENDS,
        imageFull: '/assets/images/stories/3/invite_full_1.png',
        buttonTitleAbove: 'Дружба крепкая',
        buttonTextAbove:
          'Копируйте промокод и делитесь с друзьями. Вам 2000Р за каждого и им — по 2000Р (при заказе другом от недели питания)',
        backgroundColor: '#EEBD92',
        hasRefferalButton: true,
      },
      {
        id: SlideIdEnum.INVITE_FRIENDS,
        title: 'Пригласить друга',
        imageFull: '/assets/images/stories/3/invite_full_2.png',
        buttonTitleAbove: 'Джо не делится едой',
        buttonTextAbove: 'Дарите сертификаты на рационы justfood и освобождайте время близких для важных дел',
        backgroundColor: '#EEBD92',
        buttonText: 'Подарить сертификат',
        buttonLink: 'https://t.me/JustFood_bot',
      },
    ],
  },
];

export const STORIES_DATA_ON_THANKS_PAGE: Story = {
  id: StorieIdEnum.THANKS_PAGE,
  image: '',
  text: '',
  isShowed: false,
  slides: [
    {
      id: SlideIdEnum.THANKS_PAGE_DISCOUNT,
      title: '5% за отзыв в соцсети',
      imageTop: '/assets/images/stories/thanks/thanks-discount.png',
      textParagraphs: [
        'Напишите отзыв o justfood в VK, отметив наш аккаунт либо на нашей странице и получи скидку 5% на следующий заказ. То же самое работает с Яндекс и Google.',
      ],
      textColor: '#201F1D',
      backgroundColor: '#E2FEA8',
      buttonTextAbove: 'Бонус суммируется с «Приведите друга»',
      buttonText: 'Перейти в VK',
      buttonLink: 'https://vk.com/justfoodpro',
      buttonIcon: 'vk-white',
    },
  ],
};
